<template>
  <div>
    <Toolbar class="p-mb-4">
      <template #left>
        <Button
          icon="pi pi-print"
          :disabled="add"
          @click="printList()"
          label="طباعه"
        />
      </template>
      <template #right>
        <Button
          icon="pi pi-times"
          class="p-button-danger"
          @click="$router.push('/admin/carnics')"
        />
      </template>
    </Toolbar>

    <div class="text-center" v-if="add">
      <ProgressSpinner />
    </div>
    <DataTable
      :value="list"
      :loading="loading"
      :expandedRows.sync="expandedRows"
      :filters="filters"
      ref="dt"
      dataKey="id"
    >
      <template #empty>
        لا يوجد بيانات
      </template>
      <template #loading>
        يتم تحميل البيانات. يرجي الانتظار...
      </template>
      <Column
        field="image"
        header="الشعار"
        bodyStyle="text-align: center; overflow: visible"
      >
        <template #body="slotProps">
          <img :src="$baseUploadURL + slotProps.data.image" class="dt-image" />
        </template>
      </Column>
      <Column field="userName" header="الاسم" :sortable="true">
        <template #filter>
          <input
            class="form-control"
            id="userName"
            v-model="filters['userName']"
          />
        </template>
      </Column>

      <Column :expander="true" headerStyle="width: 3rem" />

      <template #expansion="slotProps">
        <div class="orders-subtable">
          <DataTable
            :value="slotProps.data.list"
            :selection.sync="selectedItems"
            class="p-datatable-customers"
          >
            <Column
              selectionMode="multiple"
              field="id"
              headerStyle="width: 3em"
            />

            <Column field="name" header="الاسم" :sortable="true" />

            <Column field="nationalID" header="الرقم القومي" :sortable="true" />
          </DataTable>
        </div>
      </template>
    </DataTable>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id: null,
      leaguesId: null,
      list: [],
      selectedItems: [],
      expandedRows: [],
      loading: true,

      filters: {},
      body: {
        carnicsType: null,
      },
      add: false,
    };
  },
  methods: {
    printList() {
      this.add = true;

      const list = [];
      let userType = 'مدرب';

      if (this.body.carnicsType == 'mindful') {
        userType = 'اداري';
      }
      if (this.body.carnicsType == 'technical_manager') {
        userType = 'مدير فني';
      }
      if (this.body.carnicsType == 'organizers') {
        userType = item.nationalID;
      }

      for (const item of this.selectedItems) {
        list.push({
          id: item.id,
          name: item.name,
          nationalID: item.nationalID,
          academieName: item.academieId.userName,
          image: item.image,
          leaguesId: item.leaguesId.name,
          userType,
        });
      }
      this.$http
        .post(`carnics/print`, {
          list,
          body: this.body,
          baseUploadURL: this.$baseUploadURL,
        })
        .then(
          (res) => {
            this.add = false;
            window.open(this.$basePdfURL + res.data, '_blank');
            this.$toast.add({
              severity: 'success',
              summary: 'تم بنجاح',
              detail: 'تم الطباعه بنجاح',
              life: 3000,
            });
          },
          (err) => {
            this.$toast.add({
              severity: 'error',
              summary: 'هناك خطأ',
              detail: err.response.data.message,
              life: 3000,
            });
          },
        );
    },
    getData() {
      if (this.body.carnicsType) {
        let xxx=this.body.carnicsType;
        if (this.body.carnicsType == 'technical_manager') {
          xxx = 'technicalManager';
        }
        

        this.$http.get(xxx).then(
          (response) => {
            this.loading = false;
            const playersList = response.data;
            const academieList = playersList.map((el) => el.academieId);
            let uniquacademieListe = [
              ...new Map(
                academieList.map((item) => [item['id'], item]),
              ).values(),
            ];
            for (const item of uniquacademieListe) {
              item.list = playersList.filter(
                (el) => el.academieId.id == item.id,
              );
            }
            this.list = uniquacademieListe;
          },
          (err) => {
            this.loading = false;
            this.$toast.add({
              severity: 'error',
              summary: 'هناك خطأ',
              detail: err.response.data.message,
              life: 3000,
            });
          },
        );
      }
    },
  },
  created() {
    this.id = this.$route.params.id;
    this.$http.get(`carnics/${this.id}`).then(
      (res) => {
        this.body = res.data;
        this.body.selected = JSON.parse(res.data.selected);
        this.getData();
      },
      (err) => {
        this.$toast.add({
          severity: 'error',
          summary: 'هناك خطأ',
          detail: err.response.data.message,
          life: 3000,
        });
      },
    );

    this.leaguesId = this.$route.params.leaguesId;
  },
};
</script>

<style></style>
